import React, { useState, useEffect } from "react";
import { getPage } from "../lib/contentful/api";
import Head from "next/head";
import {
  items,
  getNodeKey,
  getFieldAttr,
  getNodeContentType,
} from "../lib/contentful/utilities/CtfModule";
import ErrorPage from "../pages/_error";
import { ctfModules } from "../components/contentful/index";
import Layout from "../components/layout/Layout";
import ModuleContent from "../components/ui/ModuleContent";

const HomePage = ({ ctfPage }) => {
  const [navBg, setNavBg] = useState("#001E45");

  if (!ctfPage) {
    return <ErrorPage />;
  }

  useEffect(() => {
    if (ctfPage.fields.navBackgroundColour) {
      setNavBg(ctfPage.fields.navBackgroundColour);
    }
  }, [ctfPage.fields.navBackgroundColour]);

  const seo = {
    title: getFieldAttr(ctfPage, "title"),
    description:
      getFieldAttr(ctfPage, "metadescription") + " | Dulux Colour Shop",
  };

  const modules = items(ctfPage).map((element, i) => {
    const ModuleName = ctfModules[getNodeContentType(element)];

    if (element.nodeType == "embedded-entry-block") {
      return (
        <ModuleName key={getNodeKey(element, `page-el-${i}`)} data={element} />
      );
    } else {
      return (
        <div
          className="container mx-auto px-3 mt-6"
          key={getNodeKey(element, `page-el-${i}`)}
        >
          <ModuleName data={element} />
        </div>
      );
    }
  });

  return (
    <>
      <Head>
        <meta
          name="google-site-verification"
          content="y0_Q5ColQXEAUjlNfquaKlkRKC2-Op4LyMVBtvUkDqU"
        />
      </Head>
      <Layout navBackgroundColour={navBg} seo={seo}>
        <ModuleContent>{modules}</ModuleContent>
      </Layout>
    </>
  );
};

export async function getStaticProps() {
  const ctfPage = await getPage("/");

  return {
    props: {
      ctfPage,
    },
  };
}

export default HomePage;
